import Cookies from 'js-cookie';

/* eslint-disable */
if (!window.analytics) {
  window.analytics = {};
  window.analytics.googleClickId = '';
  window.analytics.msClickId = '';
  window.analytics.expiryDate = '';
  window.analytics.clientId = '';
  window.analytics.sessionId = '';
  window.analytics.screenResolution = '';
  window.analytics.viewportSize = '';
  window.analytics.googleLanguage = '';
  window.analytics.userAgent = '';
  window.analytics.firstPartyId = '';
}

let gclid = new URLSearchParams(window.location.search).get('gclid');
if (!gclid) {
  gclid = window.theme ? window.theme.googleClickId : null;
}

let msclkid = new URLSearchParams(window.location.search).get('msclkid');
if (!msclkid) {
  msclkid = window.theme ? window.theme.msClickId : null;
}

window.getCookie = function (name) {
  var match = document.cookie.match(
    new RegExp('(^| )' + name + '=([^;]+)')
  );
  if (match) return match[2];
};

if (gclid) {
  let gclidString = JSON.stringify({
    value: gclid,
    timestamp: new Date().getTime(),
  });
  try {
    window.localStorage.setItem('googleClickId', gclidString);
  } catch (e) {
    captureError(`cannot access localStorage for setting item 'googleClickId' with message ` + e.message);
  }
}

if (msclkid) {
  let msclkidString = JSON.stringify({
    value: msclkid,
  });
  try {
    window.localStorage.setItem('msClickId', msclkidString);
  } catch (e) {
    captureError(`cannot access localStorage for setting item 'msClickId' with message ` + e.message);
  }
}

document.addEventListener('DOMContentLoaded', function () {
  window.analytics.userAgent = navigator.userAgent;
  window.getItemFromLocalStorage = function (name) {
    try {
      return window.localStorage.getItem(name) &&
        JSON.parse(window.localStorage.getItem(name));
    } catch (e) {
      captureError(`cannot access localStorage for getting item '` + name + `' with message ` + e.message);
    }
  };

  if (!window.theme.isUserLoggedIn) {
    setMsAdsParams();
    setGoogleAdsParams();
  }

  function setMsAdsParams() {
    let msclkidFromLocalStorage = window.getItemFromLocalStorage('msClickId');
    if (msclkidFromLocalStorage) {
      window.analytics.msClickId = msclkidFromLocalStorage.value;
    }
  }


  function setGoogleAdsParams() {
    let gclidFromLocalStorage = window.getItemFromLocalStorage('googleClickId');
    let gclidFromCookie = window.getCookie(gaId);
    window.analytics.firstPartyId = window.getCookie('fpid');

    if (gclidFromLocalStorage && gclidFromCookie) {
      let gclidCookieTimestamp = gclidFromCookie.split('.')[1] * 1000;
      // get the latest value in case both are present
      if (Number(gclidFromLocalStorage.timestamp) > gclidCookieTimestamp) {
        getGoogleAdsParamsFromLocalStorage(gclidFromLocalStorage);
      } else {
        getGoogleAdsParamsFromCookie(gclidFromCookie);
      }
    } else if (gclidFromCookie) {
      getGoogleAdsParamsFromCookie(gclidFromCookie);
    } else if (gclidFromLocalStorage) {
      getGoogleAdsParamsFromLocalStorage(gclidFromLocalStorage);
    }
  }


  function getGoogleAdsParamsFromCookie(cookie) {
    // Google Ads creates cookies in the following format: {version}.{timestamp_in_seconds}.{google_click_id}
    window.analytics.googleClickId = cookie.split('.')[2];
    window.analytics.expiryDate = new Date(cookie.split('.')[1] * 1000);
    window.analytics.expiryDate.setDate(window.analytics.expiryDate.getDate() + 90); // 90 day expiry window
  }

  function getGoogleAdsParamsFromLocalStorage(gclid) {
    window.analytics.googleClickId = gclid.value;
    window.analytics.expiryDate = new Date(gclid.timestamp);
    window.analytics.expiryDate.setDate(window.analytics.expiryDate.getDate() + 90); // 90 day expiry window
  }

  if (window.ga !== undefined && window.ga.loaded) {
    setGoogleAnalyticsParams(gaId);
  } else {
    var settingGaParams = setInterval(function () {
      if (window.ga === undefined || !window.ga.loaded) return;
      setGoogleAnalyticsParams(gaId);
      clearInterval(settingGaParams);
    }, 250);
  }

  function setGoogleAnalyticsParams(trackingId) {
    try {
      gtag('get', window.theme.measurementId, 'client_id', (client_id) => {
        window.analytics.clientId = client_id;
      });
      gtag('get', window.theme.measurementId, 'session_id', (session_id) => {
        window.analytics.sessionId = session_id;
      });
    } catch (e) {
      console.log('Could not get data from GA');
      console.log(e.message);
      // most probably due to the client blocking GA
      // nothing to do here, ignore without crashing
    }
  }

  function setGoogleAnalyticsParamsToTrackingObject() {
    try {
      window.gtag('get', window.analytics.measurementId, 'client_id', (client_id) => {
        window.gaTrackingObject.gcid = client_id;
      });
      window.gtag('get', window.analytics.measurementId, 'session_id', (session_id) => {
        window.gaTrackingObject.sessionId = session_id;
      });
    } catch (e) {
      console.log('Could not get data from gTag.');
      console.log(e.message);
      // most probably due to the client blocking GA
      // nothing to do here, ignore without crashing
    }
  }

  $(function ($) {
    $('.js-cookieNote_accept').click(function () {
      setGoogleAnalyticsTrackingObj(true);
    })
    setGoogleAnalyticsTrackingObj();
  })


  function setGoogleAnalyticsTrackingObj(forceTrue = false) {
    let cookiesStatus = Cookies.get('cookie_policy') || window.localStorage.getItem('cookie_policy') || forceTrue;
    window.gaTrackingObject = {
      'msclkid': window.analytics ? window.analytics.msClickId : '',
      'gclid': window.analytics ? window.analytics.googleClickId : '',
      'expiryDate': window.analytics ? window.analytics.expiryDate : '',
      'userAgent': window.analytics ? window.analytics.userAgent : '',
      //'screenResolution': window.analytics ? window.analytics.screenResolution : '',
      //'viewportSize': window.analytics ? window.analytics.viewportSize : '',
      //'googleLanguage': window.analytics ? window.analytics.googleLanguage : '',
      'source': window.analytics ? window.analytics.source : '',
    }

    if (!window.hasCookieNotification || cookiesStatus !== 'accept') {
      return;
    }
    setGoogleAnalyticsParamsToTrackingObject()
    window.gaTrackingObject.fpid = window.analytics ? window.analytics.firstPartyId : '';
  }
});
